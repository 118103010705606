* {
  box-sizing: border-box;
}

html {
  --bg: #efefef;
  --fg: #1e1e1e;
  --gray: #555;
  --light-gray: #ccc;
  --shadow: #e6e6e6;
  --success: #5ace84;
  --primary: #ff585d;
  --secondary: #0072ce;
  background-color: var(--bg);
  color: var(--fg);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  font-size: calc(.9em + .5vw);
  line-height: 1.3;
}

body {
  margin: 0;
  padding: 1em;
}

main {
  max-width: 26em;
  margin: 0 auto;
}

h1 {
  text-align: center;
  background-position: 50% 1em;
  background-repeat: no-repeat;
  background-size: auto 1.5em;
  margin-top: 0;
  padding: 0;
  font-size: 1.5em;
}

.greeting {
  color: var(--secondary);
  text-decoration: underline;
}

h2 {
  text-align: center;
}

.please-wait .change {
  pointer-events: none;
}

ul.information {
  text-align: left;
  margin: 2em 0;
  padding: 0;
  font-size: .8em;
}

.information li:first-child {
  border-top: 1px solid var(--light-gray);
}

.information li {
  border-bottom: 1px solid var(--light-gray);
  padding: .5em 0;
  list-style: none;
}

.change {
  border: 2px solid var(--light-gray);
  flex-direction: column;
  place-content: stretch space-evenly;
  align-items: stretch;
  padding: .5em;
  font-size: 1em;
  display: flex;
}

.change > div {
  place-content: stretch space-evenly;
  align-items: stretch;
  display: flex;
}

.change input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  flex: 1;
}

.change label {
  text-align: left;
  margin-right: 10px;
  padding-bottom: .5em;
  display: block;
}

.change button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

a, .link {
  color: var(--primary);
  text-decoration: none;
}

a:hover, a:focus, .link:hover, .link:focus {
  text-decoration: underline;
}

a:active, .link:active {
  color: var(--secondary);
}

button, input {
  font: inherit;
  outline: none;
}

main.please-wait .change button {
  pointer-events: none;
  background-color: #fff;
  position: relative;
}

main.please-wait .change button span {
  visibility: hidden;
}

button {
  background-color: var(--secondary);
  color: #efefef;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: .3em .75em;
  transition: transform 30ms;
}

button:hover, button:focus {
  box-shadow: inset 0 0 10em #fff3;
}

input {
  background-color: var(--light-gray);
  caret-color: var(--primary);
  color: inherit;
  border: none;
  border-radius: 5px 0 0 5px;
  padding: .25em .5em;
}

input::selection {
  background-color: var(--secondary);
  color: #efefef;
}

input:focus {
  box-shadow: inset 0 0 10em #00000005;
}

code {
  color: var(--gray);
}

li {
  padding-bottom: 1em;
}

@media (prefers-color-scheme: dark) {
  html {
    --bg: #393939;
    --fg: #efefef;
    --gray: #aaa;
    --shadow: #2a2a2a;
    --light-gray: #444;
  }

  input:focus {
    box-shadow: inset 0 0 10em #ffffff05;
  }
}

main.please-wait .loader, main.please-wait .loader:after {
  display: inline-block;
}

.loader, .loader:after {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: none;
}

.loader {
  text-indent: -9999em;
  border-top: 3px solid var(--secondary);
  border-right: 3px solid var(--secondary);
  border-bottom: 3px solid var(--secondary);
  border-left: 3px solid #fff;
  font-size: 10px;
  animation: load8 1.1s linear infinite;
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  transform: translateZ(0);
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*# sourceMappingURL=index.70d2b014.css.map */
